import * as yup from 'yup';
import { validar } from '~/utils/ie';

export const schema = yup.object({
  cod_loja: yup.number().min(0).required(),
  num_cnpj: yup
    .string()
    .required()
    .test((value) => {
      // Remove os caracteres especiais e verifica possui 11 ou 14 digitos
      value = value?.replace(/\D/g, '');
      return value?.length === 14;
    }),
  nascimentoAbertura: yup.string().required(),
  inativo: yup.boolean(),
  name: yup.string().required(),

  tipo_loja: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),

  apelido: yup.string().required(),
  email: yup.string().email().notRequired().nullable(true),
  num_ie: yup.string().notRequired(),
  grupo_empresa: yup.object().when('update', {
    is: (update: any) => {
      if (!update) return true;
      return false;
    },
    then: yup
      .object({
        label: yup.string().required(),
        value: yup.number().required(),
      })
      .required(),
    otherwise: yup
      .object({
        label: yup.string(),
        value: yup.number(),
      })
      .notRequired()
      .nullable(true),
  }),

  cep: yup.string().required(),
  logradouro: yup.string().required(),
  numero: yup.string().required(),
  bairro: yup.string().required(),
  cidades: yup
    .object()
    .shape({
      value: yup.number().required(),
      ufValue: yup.string().notRequired(),
      label: yup.string().required(),
    })
    .required(),
});

export const condicao = yup
  .object({
    condicaoFinalizadora: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    condicao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    numCondicao: yup.string().required(),
  })
  .required();
