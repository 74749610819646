import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import Textfield from '@atlaskit/textfield';
import FormAtlas, { Field, FormFooter } from '@atlaskit/form';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ImSpinner8 } from 'react-icons/im';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Context } from '~/context/auth';

import imgLogin from '~/assets/img/img-login.png';
import imgLogo from '~/assets/img/logo.png';

import {
  ButtonSubmit,
  Container,
  ContainerForm,
  ContainerImage,
  Form,
  Image,
  LinkRecovery,
} from './styles';
import api from '~/services/api';

interface LoginRequestProps {
  email: string;
  password: string;
}

interface ResponseRecovery {
  success: boolean;
  message: string;
}

const MySwal = withReactContent(Swal);

const Login: React.FC = () => {
  const { handleLogin } = useContext(Context);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async ({ email, password }: LoginRequestProps) => {
    setLoading(true);
    try {
      await handleLogin(email.trim(), password);
    } catch (err) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleRecoveryEmail = async ({ email }: LoginRequestProps) => {
    if (!email) return;

    const { data } = await api.post<ResponseRecovery>('/recovery', { email });

    if (data.success) {
      close();

      MySwal.fire({
        html: data.message,
        icon: 'success',
        showConfirmButton: false,
      });
    } else {
      toast.warning(data.message);
    }
  };
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  return (
    <Container>
      <ContainerImage>
        <Image src={imgLogin} alt="" />
      </ContainerImage>

      <ContainerForm>
        <FormAtlas onSubmit={handleSubmit}>
          {({ formProps }) => (
            <Form {...formProps}>
              <img src={imgLogo} alt="" className="logo" />
              <Field label="Email" name="email">
                {({ fieldProps }) => (
                  <>
                    <Textfield
                      {...fieldProps}
                      type="email"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const lowercaseValue = e.target.value.toLowerCase();
                        fieldProps.onChange(lowercaseValue);
                      }}
                    />
                  </>
                )}
              </Field>
              <Field label="Senha" name="password">
                {({ fieldProps }) => (
                  <>
                    <Textfield {...fieldProps} type="password" />
                  </>
                )}
              </Field>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <LinkRecovery type="button" onClick={open}>
                  Esqueceu sua senha?
                </LinkRecovery>
              </div>
              <FormFooter align="start">
                <ButtonSubmit type="submit">
                  {loading ? (
                    <CircularProgress size={15} style={{ color: '#ffffff' }} />
                  ) : (
                    <span>Entrar</span>
                  )}
                </ButtonSubmit>
              </FormFooter>
            </Form>
          )}
        </FormAtlas>
      </ContainerForm>

      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            heading="Digite seu email para recuperar sua senha"
          >
            <FormAtlas onSubmit={handleRecoveryEmail}>
              {({ formProps }) => (
                <form {...formProps} style={{ padding: '20px' }}>
                  <Field label="Email" name="email">
                    {({ fieldProps }) => (
                      <>
                        <Textfield {...fieldProps} type="email" />
                      </>
                    )}
                  </Field>

                  <FormFooter align="start">
                    <ButtonSubmit type="submit">Enviar</ButtonSubmit>
                  </FormFooter>
                </form>
              )}
            </FormAtlas>
          </Modal>
        )}
      </ModalTransition>
    </Container>
  );
};

export default Login;
