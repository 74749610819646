import Button from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataGrid, GridColumns, GridRowParams } from '@material-ui/data-grid';
import axios from 'axios';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { AiOutlineGlobal, AiOutlinePlusCircle } from 'react-icons/ai';
import { GoPencil } from 'react-icons/go';
import { MdDeleteForever } from 'react-icons/md';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CancelButton, ConfirmButton } from '~/components/Buttons';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import InputNumberDias from '~/components/Inputs/inputNumberDias';
import InputSelectControl from '~/components/Inputs/InputSelect';
import InputTextControl from '~/components/Inputs/InputText';
import {
  InputCidade,
  InputDate,
  InputMaskCelular,
  InputMaskCep,
  InputMaskCnpj,
  InputMaskTelefone,
  InputNumber2,
  InputRadio,
  InputSelect,
  InputText,
  InputText2,
} from '~/components/NovosInputs';
import { ICEP, ICNPJ } from '~/components/Pessoa/types';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import api from '~/services/api';
import { validarCNPJ } from '~/utils/functions';
import {
  addUpdate,
  deleteItens,
  insertOrUpdate,
  MasterDetailProps,
} from '~/utils/masterDetail';

import { validar } from '~/utils/ie';
import { Tipolojas } from './data';
import geral from './data/geral.json';
import {
  CondicaoProps,
  ContabilistaProps,
  RefObjectDateProps,
  SelectControlProps,
  SelectProps,
} from './protocols';
import { ButtonRow, Container } from './styles';
import { AddCondicao, CidadeParams } from './types';
import { condicao, schema } from './Validations';
import { loadingContext } from '../../context/loading';
import { SelectType } from '~/components/NovosInputs/InputCidade/protocols';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

const MySwal = withReactContent(Swal);

const Loja: React.FC = () => {
  const { setLoading } = useContext(loadingContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const masterDetailDefault = [
    {
      obj_name: 'condicao',
      pk_fields: ['cod_seq_pessoa_condicao'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
  ];

  const [masterDetail, setMasterDetail] =
    useState<MasterDetailProps[]>(masterDetailDefault);
  const nascimentoRef = useRef<RefObjectDateProps>();
  const [loja, setLoja] = useState<number | undefined>(undefined);
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [manterDados, setManterDados] = useState(false);
  const [tab, setTab] = useState<any>('geral');

  const [init, setInit] = useState<boolean>(false);
  const [tipoGrupoEmpresa, setTipoGrupoEmpresa] = useState<number>(1);
  const [grupoEmpresa, setGrupoEmpresa] = useState<SelectControlProps>({
    value: undefined,
    label: '',
  });
  const [desGrupoEmpresa, setDesGrupoEmpresa] = useState<string>('');
  const [flg_matriz, setFlg_matriz] = useState<boolean>(false);

  const [loadingIcon, setLoadingIcon] = useState<string>();

  const [flagInativo, setFlagInativo] = useState<boolean>(false);
  const [cidade, setCidade] = useState<CidadeParams>();
  const [clearCidade, setClearCidade] = useState<boolean>(false);
  const [finalizadoraInit, setFinalizadoraInit] = useState<boolean>(false);

  const [selectGrupoEmpresa, setSelectGrupoEmpresa] = useState<SelectProps[]>(
    [],
  );

  const [finalizadoras, setFinalizadoras] = useState<SelectProps[]>([]);
  const [condicoes, setCondicoes] = useState<AddCondicao[]>([]);
  const [condicoesOptions, setCondicoesOptions] = useState<SelectProps[]>([]);
  const [contabilista, setContabilista] = useState<SelectProps[]>([]);
  const [numCondicao, setNumCondicao] = useState<number>();
  const [condicaoAEditar, setCondicaoAEditar] = useState<any>(undefined);
  const [cidadeSelected, setCidadeSelected] = useState<SelectType>();

  const colunasCondicao: GridColumns = [
    {
      field: 'uuid',
      headerName: 'Id',
      hide: true,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'des_finalizadora',
      headerName: 'Finalizadora',
      type: 'string',
      flex: 2,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'num_condicao',
      headerName: 'Condição',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      sortable: true,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'des_condicao',
      headerName: 'Tipo da Condição',
      flex: 2,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: '',
      headerName: 'Ações',
      width: 180,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: (params: { row: any }) => {
        const onEdit = () => {
          const { row } = params;
          setValueCondicao('condicaoFinalizadora', {
            value: row.cod_finalizadora,
            label: row.des_finalizadora,
          });
          setValueCondicao('condicao', {
            value: row.cod_condicao,
            label: row.des_condicao,
          });
          setNumCondicao(row.num_condicao);
          setCondicaoAEditar({
            uuid: row.uuid,
            cod_seq_pessoa_condicao: row.cod_seq_pessoa_condicao,
          });
          const elm = document.getElementById('collapseThree-loja');
          if (elm) {
            elm.removeAttribute('class');
            elm.classList.add('accordion-collapse', 'collapse', 'show');
          }
        };
        const onDelete = () => {
          const { row } = params;
          handleRemoveCondicao(row.id);
        };

        return (
          <>
            <ButtonRow type="button" onClick={onEdit}>
              <GoPencil size={20} style={{ color: '#72ab90' }} /> Editar
            </ButtonRow>
            <ButtonRow type="button" onClick={onDelete}>
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Excluir
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const inputCidadeRef = useRef<HTMLDivElement | null>(null);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const watchTipoLoja = watch('tipo_loja');
  const watchContabilista = watch('cod_pessoa_contabilista');

  const {
    register: condicaoRegister,
    handleSubmit: handleSubmitCondicao,
    control: condicaoControl,
    reset: resetCondicao,
    setValue: setValueCondicao,
    formState: formStateCondicao,
    clearErrors: clearErrorsCondicao,
  } = useForm({
    resolver: yupResolver(condicao),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (inputCidadeRef.current) {
      const inputCidade: HTMLInputElement | null =
        inputCidadeRef.current.querySelector('[name="cidades"]');

      if (inputCidade && cidadeSelected) {
        const { label } = cidadeSelected;
        const { value } = inputCidade;

        if (value !== label) inputCidade.value = label;
      }
    }
  }, [cidadeSelected]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/condicao');
      if (data.success && data.message !== 'Nenhum registro encontrado.') {
        const formatedOptions = data.data.map((cond: CondicaoProps) => ({
          label: `${cond.des_condicao} - ${cond.des_definicao}`,
          value: cond.cod_condicao,
        }));
        setCondicoesOptions(formatedOptions);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (tipoGrupoEmpresa === 1) {
        const { data } = await api.get('/grupoempresa/grupos');

        const grupos = data.data.map(
          (grupo: { cod_grupo_empresa: number; des_grupo_empresa: string }) => {
            return {
              value: grupo.cod_grupo_empresa,
              label: grupo.des_grupo_empresa,
            };
          },
        );
        setSelectGrupoEmpresa(grupos);
      }
    })();
  }, [tipoGrupoEmpresa]);

  useEffect(() => {
    (async () => {
      getContabilistaLoja();
    })();
  }, []);

  const getContabilistaLoja = async () => {
    try {
      const { data } = await api.get('/lojas/contabilista');
      if (data.success && data.message !== 'Nenhum registro encontrado.') {
        const formatedOptions = data.data.map((cont: ContabilistaProps) => ({
          label: `${cont.des_fornecedor}`,
          value: cont.cod_pessoa,
        }));
        setContabilista(formatedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRowClick = useCallback(
    async (param: GridRowParams) => {
      const { row } = param;
      getContabilistaLoja();

      const {
        cod_loja,
        cod_pessoa,
        num_cpf_cnpj,
        des_loja,
        num_ie,
        num_insc_municipal,
        cod_cnae,
        num_nire,
        tipo_loja,
        flg_inativo,
        nome_pessoa,
        apelido_fantasia,
        dta_nascimento_abertura,
        num_telefone,
        num_celular,
        des_contato,
        email,
        site,
        num_cep,
        des_logradouro,
        num_endereco,
        des_bairro,
        des_complemento,
        des_cidade,
        des_uf,
        cod_pessoa_contabilista,
      } = row;
      setInit(false);
      setValue('update', true);
      setUpdate(true);
      setLoja(cod_loja);
      setValue('cod_loja', cod_loja);
      setValue('cod_pessoa', cod_pessoa);
      setValue('num_cnpj', num_cpf_cnpj);
      setValue('num_ie', num_ie);
      setValue('cod_cnae', cod_cnae);
      setValue('num_insc_municipal', num_insc_municipal);
      setValue('num_nire', num_nire);
      setValue('inativo', flg_inativo);
      setFlagInativo(flg_inativo);
      setValue('name', nome_pessoa ?? des_loja);
      setValue('apelido', apelido_fantasia);
      setValue(
        'nascimentoAbertura',
        dta_nascimento_abertura
          ? moment(
              createDateWithoutTimezone(dta_nascimento_abertura),
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD')
          : null,
      );
      setValue(
        'tipo_loja',
        Tipolojas.find((item) => item.value === tipo_loja),
      );
      setValue(
        'cod_pessoa_contabilista',
        contabilista.find((item) => item.value === cod_pessoa_contabilista),
      );

      setValue('telefone', num_telefone);
      setValue('celular', num_celular);
      setValue('contato', des_contato);
      setValue('email', email);
      setValue('site', site);
      setValue('cep', num_cep);
      setValue('logradouro', des_logradouro);
      setValue('numero', num_endereco);
      setValue('bairro', des_bairro);
      setValue('complemento', des_complemento);
      setCidade({
        cidade: des_cidade,
        uf: des_uf,
      });
      setNumCondicao(30);
      setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
      setValueCondicao('numCondicao', 30);
      // Busca condicoes
      {
        const { data } = await api.get(`/cliente-condicao`, {
          params: {
            cod_pessoa,
            tipo_condicao: 1,
          },
        });
        if (data.success) {
          if (data.message !== 'Nenhum registro encontrado.') {
            const options = data.data.map((cond: any) => {
              const uuid = nanoid();
              return {
                uuid,
                id: uuid,
                cod_seq_pessoa_condicao: cond.cod_seq_pessoa_condicao,
                num_condicao: `${cond.num_condicao}`,
                des_condicao: cond.des_condicao,
                des_finalizadora: cond.des_finalizadora,
                cod_finalizadora: cond.cod_finalizadora,
                cod_condicao: cond.cod_condicao,
              };
            });
            await addUpdate('condicao', options, masterDetail, setMasterDetail);
            setCondicoes(options);
          }
        }
      }
      setShowSearch(false);
    },
    [setValue, setValueCondicao, masterDetail, contabilista],
  );

  const handleClearCondicao = useCallback(() => {
    setCondicaoAEditar('');
    setFinalizadoraInit(false);
    setNumCondicao(30);
    setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
    setValueCondicao('numCondicao', 30);
    setValueCondicao('condicaoFinalizadora', '');
    clearErrorsCondicao('numCondicao');
  }, [setValueCondicao]);

  const resetFormData = useCallback(() => {
    reset();
    setCidade(undefined);
    setClearCidade(true);
    setMasterDetail(masterDetailDefault);
    reset(geral);
    setFlg_matriz(false);
    setLoja(undefined);
    resetCondicao();
    setCondicoes([]);
    setUpdate(false);
    setValue('update', false);
    setFlagInativo(false);
    setInit(false);
    setShowSearch(false);
    setTab('geral');
    setTipoGrupoEmpresa(1);
    setValue('tipoGrupoEmpresa', 1);
    setCondicaoAEditar('');
    nascimentoRef.current?.changeStartDate('');
    setGrupoEmpresa({
      value: undefined,
      label: '',
    });
    setDesGrupoEmpresa('');
    handleClearCondicao();
    setTimeout(() => {
      setClearCidade(false);
    }, 1000);
  }, [
    reset,
    resetCondicao,
    setValue,
    masterDetailDefault,
    handleClearCondicao,
  ]);

  function validationInputGrupoEmpresa(): boolean {
    const { grupo_empresa, des_grupo_empresa } = getValues();
    if (tipoGrupoEmpresa === 0 && des_grupo_empresa === '') return true;
    if (tipoGrupoEmpresa === 1 && grupo_empresa.value === undefined)
      return true;
    return false;
  }

  const onSubmit = handleSubmit(async (data) => {
    if (data.num_cnpj) {
      const isValid = documentoValido(data.num_cnpj);
      if (!isValid) {
        return;
      }
    }
    if (data.cod_cnae && data.cod_cnae !== '') {
      if (data.cod_cnae.length !== 7) {
        setError('cod_cnae', {
          type: 'error',
        });
        return;
      }
    }
    data.num_ie = data.num_ie.trim();
    if (data.num_ie === '' || !data.num_ie) {
      setLoading(false);
      const result = await MySwal.fire({
        title: 'Confirmação',
        text: 'Inscrição Estadual não foi informada. Deseja cadastrá-lo como "ISENTO"',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      });

      if (!result.isConfirmed) {
        setError('num_ie', {
          type: 'error',
        });
        return;
      }
      data.num_ie = 'ISENTO';
    } else {
      const ie = getValues('num_ie');
      const cidades = getValues('cidades');

      if (!cidades || !cidades.ufValue) {
        return true; // No validation if no state is selected
      }
      if (!validar(ie, cidades.ufValue)) {
        setError('num_ie', {
          type: 'error',
        });
        return;
      }
    }
    try {
      data = {
        ...data,
        des_cidade: data.cidades.label.split(' - ')[0],
        cod_cidade: data.cidades.value,
        des_uf: data.cidades.label.split(' - ')[1],
      };

      if (data.cod_pessoa_contabilista) {
        data.cod_pessoa_contabilista = data.cod_pessoa_contabilista.value;
      }

      setInit(true);
      setLoading(true);
      if (isUpdate) {
        const res = await api.put(`/lojas/${loja}`, {
          data,
          masterDetail,
        });
        const { success, message } = res.data;
        if (success) {
          resetFormData();
          setShowSearch(true);
          toast.success(message);
        }
      } else {
        if (validationInputGrupoEmpresa()) {
          return;
        }
        const res = await api.post('/lojas', {
          data,
          masterDetail,
        });
        const { success, message } = res.data;
        if (success) {
          if (tipoGrupoEmpresa === 0) {
            const lojas = [
              {
                cod_loja: data.cod_loja,
                flg_matriz: true,
              },
            ];
            const dataGrupoEmpresa = {
              des_grupo_empresa: data.des_grupo_empresa,
              lojas,
            };
            await api.post('/grupoempresa', dataGrupoEmpresa);
          } else {
            const formGrupoEmpresa = {
              cod_grupo_empresa: data.grupo_empresa.value,
              cod_loja: data.cod_loja,
              flg_matriz,
            };
            await api.post('/grupoempresa/novaloja', formGrupoEmpresa);
          }

          resetFormData();
          setShowSearch(false);

          toast.success(message);
        }
      }
    } catch (error: any) {
      setLoader(false);
    }
  });

  const handleSwitch = useCallback(() => {
    setManterDados(!manterDados);
  }, [manterDados]);

  const documentoValido = (doc: string): boolean => {
    const validarDocumento = (
      numDocumento: string,
      mensagemErro: string,
    ): boolean => {
      if (!numDocumento) {
        toast.warn(mensagemErro);
        return false;
      }

      const isValid = validarCNPJ(numDocumento);

      if (!isValid) {
        setError('num_cnpj', { type: 'invalid' });
        toast.warn('CNPJ informado é inválido');
      }

      return isValid;
    };

    return validarDocumento(doc, 'CNPJ informado é inválido');
  };

  const searchEmpresa = useCallback(async () => {
    /**
     * Efetua busca dos dados da empresa
     */
    let cnpj = getValues('num_cnpj');

    if (loadingIcon !== 'buscaPessoa') {
      setLoadingIcon('buscaPessoa');
      if (!cnpj) {
        toast.warning('CNPJ Inválido');
        setLoadingIcon('');
        return;
      }
      const isValid = documentoValido(cnpj);
      if (!isValid) {
        setLoadingIcon('');
        return;
      }
      cnpj = cnpj.replace(/[^\d]/g, '');
      const res = await api.get(`/loja/busca-loja/${cnpj}`);
      if (res.data.success && res.data.data[0]) {
        await MySwal.fire({
          title: 'Loja já cadastrada',
          text: `Já existe uma Loja cadastrada com documento ${cnpj}. Deseja editar o registro?`,
          showCancelButton: true,
          confirmButtonColor: '#8850BF',
          cancelButtonColor: '#DE350B',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result: any) => {
          if (result.isConfirmed) {
            setLoadingIcon('');
            setValue('update', true);
            setUpdate(true);

            const dataAbertura = moment(
              res.data.data[0].dta_nascimento_abertura,
            )
              .local()
              .format('YYYY-MM-DD');

            const row: any = { row: res.data.data[0] };
            onRowClick(row);
            setLoja(res.data.data[0].cod_loja);
            setValue('cod_loja', res.data.data[0].cod_loja);
            setValue('cod_pessoa', res.data.data[0].cod_pessoa);
            setValue('num_cnpj', res.data.data[0].num_cnpj);
            setValue(
              'num_ie',
              res.data.data[0].num_ie
                ? res.data.data[0].num_ie
                : res.data.data[0].num_rg_ie,
            );
            setValue('num_insc_municipal', res.data.data[0].num_im);
            setValue('num_nire', res.data.data[0].num_nire);
            setValue('inativo', res.data.data[0].flg_inativo);
            setValue(
              'nascimentoAbertura',
              res.data.data[0].dta_abertura ? dataAbertura : null,
            );
            setFlagInativo(res.data.data[0].flg_inativo);
            setValue('apelido', res.data.data[0].des_fantasia);
          } else {
            setLoadingIcon('');
            return false;
          }
        });
      } else {
        const { data } = await api.get(`/pessoas/num_cpf_cnpj/${cnpj}`);
        if (data.success && data.data && data.data.length > 0) {
          toast.success(
            `Documento já cadastrado. Dados importados com sucesso.`,
          );
          setLoadingIcon('');
          setValue('cod_pessoa', data.data[0].cod_pessoa);
          setValue('cod_contato', data.data[0].cod_contato);
          setValue('cod_endereco', data.data[0].cod_endereco);
          setValue('num_ie', data.data[0].num_ie);
          setValue('name', data.data[0].nome_pessoa);
          setValue('apelido', data.data[0].apelido_fantasia);
          setValue('telefone', data.data[0].num_telefone);
          setValue('celular', data.data[0].num_celular);
          setValue('contato', data.data[0].des_contato);
          setValue('email', data.data[0].email);
          setValue('site', data.data[0].site);
          setValue('cep', data.data[0].num_cep);
          setValue('logradouro', data.data[0].des_logradouro);
          setValue('numero', data.data[0].num_endereco);
          setValue('bairro', data.data[0].des_bairro);
          setValue('complemento', data.data[0].des_complemento);
          setCidade({
            cidade: data.data[0].des_cidade,
            uf: data.data[0].des_sigla,
          });
          setValue(
            'nascimentoAbertura',
            data.data[0].dta_nascimento_abertura
              ? moment(data.data[0].dta_nascimento_abertura)
                  .local()
                  .format('YYYY-MM-DD')
              : null,
          );
          nascimentoRef.current?.changeStartDate(
            data.data[0].dta_nascimento_abertura,
          );
        } else {
          try {
            const dataBusca: ICNPJ = await axios.get(
              `https://brasilapi.com.br/api/cnpj/v1/${cnpj}`,
            );
            if (dataBusca.data) {
              setValue('name', dataBusca.data.razao_social);
              setValue('apelido', dataBusca.data.nome_fantasia);
              setValue('telefone', dataBusca.data.ddd_telefone_1.trim());
              setValue('celular', dataBusca.data.ddd_telefone_1.trim());
              setValue('cep', dataBusca.data.cep);
              setValue('logradouro', dataBusca.data.logradouro);
              setValue('numero', dataBusca.data.numero);
              setValue('bairro', dataBusca.data.bairro);
              setValue('complemento', dataBusca.data.complemento);
              setCidade({
                cidade: dataBusca.data.municipio,
                uf: dataBusca.data.uf,
              });
              setValue(
                'nascimentoAbertura',
                dataBusca.data.data_inicio_atividade
                  ? moment(dataBusca.data.data_inicio_atividade)
                      .local()
                      .format('YYYY-MM-DD')
                  : null,
              );
              nascimentoRef.current?.changeStartDate(
                dataBusca.data.data_inicio_atividade,
              );
              setLoadingIcon('');
              return;
            }
          } catch (error: any) {
            toast.warn(
              'Não foram encontrados dados públicos para o documento informado.',
            );
            setLoadingIcon('');
          }
        }
      }
      setLoadingIcon('');
    }
  }, [getValues, setValue, loadingIcon, onRowClick]);

  async function handleDelete() {
    const { data } = await api.delete(`/lojas/${loja}`);

    if (data.success) {
      setShowSearch(true);
      resetFormData();
      toast.success(data.message);
    } else {
      throw new Error(data.message);
    }
  }

  const openWhatsApp = useCallback(() => {
    let celular = getValues('celular');
    const contato = getValues('contato');

    const regex = new RegExp(
      /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/g,
    );
    const validacao = !regex.test(celular);

    celular = celular.replace(/[^\d]/g, '');

    if (!celular || validacao) {
      toast.warning('Informe um número de celular válido');
      return;
    }

    celular = celular.replace(/[^\d]/g, '');
    if (!contato) {
      window.open(`https://api.whatsapp.com/send?phone=55${celular}`);
      return;
    }

    window.open(
      `https://api.whatsapp.com/send?phone=55${celular}&text=${encodeURI(
        `Olá, ${contato}`,
      )}`,
    );
  }, [getValues]);

  const openSite = useCallback(() => {
    const site = getValues('site');
    if (!site) {
      return;
    }
    window.open(`https://${site}`);
  }, [getValues]);

  const searchCep = useCallback(async () => {
    if (loadingIcon !== 'cep') {
      setLoadingIcon('cep');
      let cep = getValues('cep');
      if (!cep) {
        toast.warning('CEP inválido');
        setLoadingIcon('');
        return;
      }
      cep = cep.replace(/[^\d]/g, '');
      if (cep === '') {
        toast.warning('CEP inválido');
        setLoadingIcon('');
        return;
      }
      try {
        const data: ICEP = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${cep}`,
        );
        if (data.data && !data.message) {
          setValue(
            'logradouro',
            data.data.street && data.data.street.toUpperCase(),
          );
          setValue(
            'bairro',
            data.data.neighborhood && data.data.neighborhood.toUpperCase(),
          );
          setCidade({
            cidade: data.data.city && data.data.city.toUpperCase(),
            uf: data.data.state,
          });
          setValue('latitude', data.data.location.coordinates.latitude);
          setValue('longitude', data.data.location.coordinates.longitude);
          setLoadingIcon('');
          return;
        }
        if (data.message) {
          setLoadingIcon('');
          return;
        }
      } catch (error: any) {
        setLoadingIcon('');
        toast.warn('CEP não encontrado');
        return;
      }
      setLoadingIcon('');
    }
  }, [getValues, setValue, loadingIcon]);

  // CONDICOES
  useEffect(() => {
    (async () => {
      const { data } = await api.get('/finalizadora');
      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data.map((ent: any) => ({
            label: `${ent.des_finalizadora}`,
            value: ent.cod_finalizadora,
          }));

          setFinalizadoras(options);
        }
      }

      setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
      setValueCondicao('numCondicao', 30);
    })();
  }, []);

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const { clipboardData } = event;
    const pastedText = clipboardData.getData('text');
    if (/[a-zA-Z]/.test(pastedText)) {
      const numericText = pastedText.replace(/[^0-9]/g, '');
      setValue('ano_nf', numericText);
      event.preventDefault();
    }
  };

  const handleKeyDown = (event: any) => {
    if ([69, 109, 107, 194, 110].includes(event.keyCode)) {
      event.preventDefault();
    }
  };

  const handleCondicao = handleSubmitCondicao(async (data) => {
    const uuid =
      condicaoAEditar?.uuid === undefined ? nanoid() : condicaoAEditar?.uuid;
    const exists = condicoes.filter(
      (cond) =>
        cond.cod_finalizadora === data.condicaoFinalizadora.value &&
        cond.cod_condicao === data.condicao.value &&
        cond.num_condicao === data.numCondicao &&
        condicaoAEditar?.uuid !== cond.uuid,
    );
    if (exists.length > 0) {
      toast.warning(`Já existe uma condição com essas informações.`);
      return;
    }
    const condicaoData: any = {
      uuid,
      id: uuid,
      num_condicao: data.numCondicao,
      cod_seq_pessoa_condicao: condicaoAEditar?.cod_seq_pessoa_condicao,
      des_condicao: data.condicao.label,
      des_finalizadora: data.condicaoFinalizadora.label,
      cod_finalizadora: data.condicaoFinalizadora.value,
      cod_condicao: data.condicao.value,
    };
    const condicaoDetail: any[] = await insertOrUpdate(
      'condicao',
      condicaoData,
      masterDetail,
      setMasterDetail,
    );
    setCondicoes(condicaoDetail);
    setNumCondicao(30);
    setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
    setValueCondicao('numCondicao', 30);
    setValueCondicao('condicaoFinalizadora', '');
    setCondicaoAEditar('');
  });

  const handleRemoveCondicao = useCallback(
    async (uuid) => {
      const condicaoDetails: any[] = await deleteItens(
        'condicao',
        uuid,
        masterDetail,
        setMasterDetail,
      );
      setCondicoes(condicaoDetails);
      handleClearCondicao();
    },
    [handleClearCondicao, masterDetail],
  );

  const getCodLoja = async () => {
    setLoadingIcon('buscaProximoCod');
    const { data } = await api.get('/lojas/proximo-codigo');
    if (data.success) {
      setValue('cod_loja', data.data.cod_loja);
    }
    setLoadingIcon('');
  };

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }
  return (
    <Container>
      {showSearch && (
        <Search codTela={3} newData={resetFormData} onRowClick={onRowClick} />
      )}

      {!showSearch && (
        <FormDefault
          codTela={3}
          title="Cadastro de Loja"
          subTitle={
            getValues('cod_loja')
              ? `Loja ${getValues('cod_loja')} - ${getValues('name')}`
              : ''
          }
          codigoRegistro={[
            {
              value: loja,
              des_campo: 'Código',
            },
          ]}
          onSave={onSubmit}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={resetFormData}
          onDelete={handleDelete}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            resetFormData();
            setShowSearch(true);
          }}
          showSwitch={false}
          onSwitch={handleSwitch}
          isDelete={false}
        >
          <br />
          <Tabs
            id="controlled-tab-cliente"
            activeKey={tab}
            onSelect={(k) => setTab(k)}
          >
            <Tab eventKey="geral" title="Geral">
              <>
                <Row>
                  <Col sm={12} md={12} lg={2}>
                    <div className="input-container">
                      <InputNumber2
                        label="Código"
                        name="cod_loja"
                        maxLength={9}
                        min={1}
                        isError={!!errors.cod_loja}
                        control={control}
                        max={999999999}
                        hasButton
                        loadingIcon={loadingIcon === 'buscaProximoCod'}
                        clickedButton={!isUpdate ? getCodLoja : undefined}
                        placeholder=""
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          if (event.target.value.length <= 9) {
                            setValue('cod_loja', event.target.value);
                          }
                        }}
                        register={register}
                        disabled={isUpdate}
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={3}>
                    <div className="input-container">
                      <InputMaskCnpj
                        label="CNPJ"
                        name="num_cnpj"
                        isError={!!errors.num_cnpj}
                        control={control}
                        hasButton
                        loadingIcon={loadingIcon === 'buscaPessoa'}
                        clickedButton={searchEmpresa}
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={2}>
                    <Tooltip position="top" content="Inscrição Estadual">
                      <InputText
                        toLowerCase={false}
                        title="Inscrição Estadual"
                        label="IE"
                        maxLength={18}
                        placeholder=""
                        control={control}
                        name="num_ie"
                        register={register}
                        disabled={false}
                        isError={!!errors.num_ie}
                      />
                    </Tooltip>
                  </Col>
                  <Col sm={12} md={12} lg={2}>
                    <Tooltip position="top" content="Inscrição Municipal">
                      <InputText2
                        title="Inscrição Municipal"
                        label="IM"
                        maxLength={18}
                        placeholder=""
                        control={control}
                        name="num_insc_municipal"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          if (event.target.value.length <= 18) {
                            setValue('num_insc_municipal', event.target.value);
                          }
                        }}
                        register={register}
                        disabled={false}
                        isError={!!errors.num_insc_municipal}
                      />
                    </Tooltip>
                  </Col>
                  <Col sm={12} md={12} lg={2}>
                    <InputText2
                      label="NIRE"
                      maxLength={18}
                      placeholder=""
                      control={control}
                      type="number"
                      name="num_nire"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        if (event.target.value.length <= 18) {
                          setValue('num_nire', event.target.value);
                        }
                      }}
                      register={register}
                      disabled={false}
                      isError={!!errors.num_nire}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={1}>
                    <ToggleDefault
                      labelText="Inativo?"
                      setChecked={flagInativo}
                      onSwitch={() => {
                        setValue('inativo', !flagInativo);
                        setFlagInativo(!flagInativo);
                      }}
                    />
                  </Col>
                </Row>
                <Row md={12}>
                  <Col sm={12} md={12} lg={4}>
                    <InputText
                      label="Razão Social"
                      maxLength={100}
                      placeholder="Informe a Razão Social"
                      name="name"
                      register={register}
                      control={control}
                      caseInput="upper"
                      disabled={false}
                      isError={!!errors.name}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={2}>
                    <InputText
                      label="Nome Fantasia"
                      maxLength={50}
                      placeholder="Informe o Nome Fantasia"
                      name="apelido"
                      register={register}
                      control={control}
                      caseInput="upper"
                      disabled={false}
                      isError={!!errors.apelido}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={2}>
                    <InputDate
                      label="Abertura"
                      placeholder=""
                      name="nascimentoAbertura"
                      register={register}
                      control={control}
                      disabled={false}
                      isError={!!errors.nascimentoAbertura}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={2}>
                    <InputNumber2
                      label="CNAE"
                      placeholder=""
                      name="cod_cnae"
                      register={register}
                      control={control}
                      disabled={false}
                      isError={!!errors.cod_cnae}
                      maxLength={7}
                      max={9999999}
                      min={0}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        if (event.target.value.length > 7) {
                          event.target.value = event.target.value.slice(0, 7);
                        }
                        setValue('cod_cnae', event.target.value);
                      }}
                      onPaste={handlePaste}
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={2}>
                    <InputSelect
                      label="Tipo de Loja"
                      placeholder="Selecione"
                      value={watchTipoLoja?.label || ''}
                      maxLength={50}
                      name="tipo_loja"
                      register={register}
                      isError={!!errors.tipo_loja}
                      control={control}
                      options={Tipolojas}
                      changeSelected={(selected) => {
                        clearErrors('tipo_loja');
                        setValue('tipo_loja', selected);
                      }}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <InputSelect
                      label="Contabilista"
                      placeholder="Selecione"
                      name="cod_pessoa_contabilista"
                      register={register}
                      value={watchContabilista?.label || ''}
                      isError={!!errors.cod_pessoa_contabilista}
                      control={control}
                      options={contabilista}
                      changeSelected={(selected) =>
                        setValue('cod_pessoa_contabilista', selected)
                      }
                      setValue={setValue}
                    />
                  </Col>
                  <Col sm={12}>
                    <Separator labelText="Contato" />
                  </Col>
                  <Col sm={12} md={12} lg={2}>
                    <InputMaskTelefone
                      label="Telefone"
                      name="telefone"
                      isError={!!errors.telefone}
                      control={control}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={3}>
                    <div className="input-container">
                      <InputMaskCelular
                        label="Celular"
                        name="celular"
                        isError={!!errors.celular}
                        control={control}
                        hasButton
                        clickedButton={openWhatsApp}
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={7}>
                    <InputText
                      label="Contato"
                      maxLength={50}
                      placeholder="Informe o Contato"
                      name="contato"
                      register={register}
                      control={control}
                      caseInput="upper"
                      disabled={false}
                      isError={!!errors.contato}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <InputText
                      toLowerCase
                      label="E-mail"
                      maxLength={100}
                      placeholder="Informe o E-mail"
                      name="email"
                      register={register}
                      control={control}
                      disabled={false}
                      isError={!!errors.email}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <div className="input-container">
                      <InputText
                        label="Site"
                        maxLength={150}
                        placeholder="Informe a página WEB"
                        name="site"
                        register={register}
                        control={control}
                        disabled={false}
                        isError={!!errors.site}
                      />
                      <Button
                        type="button"
                        className="min-button"
                        onClick={openSite}
                      >
                        <AiOutlineGlobal
                          color="#fff"
                          style={{ marginTop: '-2px' }}
                        />
                      </Button>
                    </div>
                  </Col>
                  <Col sm={12}>
                    <Separator labelText="Endereço" />
                  </Col>
                  <Col sm={12} md={12} lg={2}>
                    <div className="input-container">
                      <InputMaskCep
                        label="CEP"
                        name="cep"
                        isError={!!errors.cep}
                        control={control}
                        hasButton
                        loadingIcon={loadingIcon === 'cep'}
                        clickedButton={searchCep}
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={5}>
                    <InputText
                      label="Logradouro"
                      maxLength={100}
                      placeholder="Informe o Logradouro"
                      name="logradouro"
                      register={register}
                      control={control}
                      caseInput="upper"
                      disabled={false}
                      isError={!!errors.logradouro}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={2}>
                    <InputText
                      label="Número"
                      maxLength={15}
                      placeholder="Informe o Número"
                      name="numero"
                      register={register}
                      control={control}
                      disabled={false}
                      isError={!!errors.numero}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={3}>
                    <InputText
                      label="Bairro"
                      maxLength={100}
                      placeholder="Informe o Bairro"
                      name="bairro"
                      register={register}
                      control={control}
                      caseInput="upper"
                      disabled={false}
                      isError={!!errors.bairro}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={8}>
                    <InputText
                      label="Complemento"
                      maxLength={50}
                      placeholder="Informe o Complemento"
                      name="complemento"
                      register={register}
                      control={control}
                      disabled={false}
                      isError={!!errors.complemento}
                    />
                  </Col>
                  <Col sm={12} md={12} lg={4} ref={inputCidadeRef}>
                    <InputCidade
                      label="Cidade"
                      maxLength={29}
                      placeholder="Selecione a Cidade"
                      name="cidades"
                      register={register}
                      isError={!!errors.cidades}
                      control={control}
                      cidadeABuscar={cidade}
                      clearCampo={clearCidade}
                      changeSelected={(selected) => {
                        clearErrors('cidades');
                        setValue('cidades', selected);
                        setCidadeSelected(selected);
                      }}
                      setValue={setValue}
                    />
                  </Col>
                </Row>
                {!isUpdate && (
                  <>
                    <Separator labelText="Grupo de Empresa" />
                    <Row>
                      <Col
                        sm={12}
                        md={12}
                        className="d-flex justify-content-center"
                      >
                        <InputRadio
                          label="Grupo de Empresa Existente"
                          name="tipoGrupoEmpresa"
                          value={1}
                          register={register}
                          disabled={false}
                          checked={tipoGrupoEmpresa === 1}
                          onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                            setTipoGrupoEmpresa(Number(e.currentTarget.value))
                          }
                          isError={!!errors.tipoGrupoEmpresa}
                        />
                        <InputRadio
                          label="Novo Grupo de Empresa"
                          name="tipoGrupoEmpresa"
                          value={0}
                          register={register}
                          disabled={false}
                          checked={tipoGrupoEmpresa === 0}
                          onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                            setTipoGrupoEmpresa(Number(e.currentTarget.value))
                          }
                          isError={!!errors.tipoGrupoEmpresa}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {tipoGrupoEmpresa === 0 ? (
                        <Col sm={12} md={12}>
                          <InputTextControl
                            label="Descrição"
                            maxLength={50}
                            placeholder=""
                            value={desGrupoEmpresa}
                            onChange={(value) => {
                              setDesGrupoEmpresa(value);
                              setValue('des_grupo_empresa', value);
                            }}
                            isRequired
                            setInvalid={desGrupoEmpresa === ''}
                            iniInicializado={init}
                          />
                        </Col>
                      ) : (
                        <>
                          <Col
                            sm={12}
                            md={10}
                            className="containerEmpresaGrupo"
                          >
                            <InputSelectControl
                              label="Selecionar Grupo de Empresa"
                              placeholder="Selecione..."
                              options={selectGrupoEmpresa}
                              value={grupoEmpresa}
                              onChange={(selected: SelectProps) => {
                                setValue('grupo_empresa', selected);
                                setGrupoEmpresa(selected);
                              }}
                              isRequired
                              setInvalid={!!errors.grupo_empresa}
                              iniInicializado={!!errors.grupo_empresa}
                            />
                          </Col>
                          <Col sm={12} md={2} className="mt-4 text-nowrap">
                            <ToggleDefault
                              labelText="Tornar Matriz do Grupo?"
                              name="flg_matriz"
                              setChecked={flg_matriz}
                              onSwitch={() => setFlg_matriz(!flg_matriz)}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </>
                )}
              </>
            </Tab>
            <Tab eventKey="condicao" title="Condição">
              <Row>
                <Col sm={12} md={12}>
                  <Separator
                    labelText="Condição de Pagamento"
                    childrenWidth="170px"
                  >
                    <button
                      type="button"
                      className="btn-condicao-loja"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree-loja"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                      style={{
                        marginTop: '-7px',
                        backgroundColor: '#F4F5F7',
                      }}
                    >
                      <AiOutlinePlusCircle
                        size={16}
                        style={{ marginTop: '-2px', color: 'green' }}
                      />{' '}
                      Adicionar Condição
                    </button>
                  </Separator>
                </Col>
                {/* Inicio accordion */}
                <div
                  className="accordion"
                  id="accordionCondicaoLoja"
                  style={{ marginBottom: '30px', padding: '0px' }}
                >
                  <div className="accordion-item">
                    <div
                      id="collapseThree-loja"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionCondicao"
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col
                            className="finalizadora-container"
                            sm={12}
                            md={3}
                          >
                            <InputSelect
                              label="Finalizadora"
                              placeholder="Selecione"
                              name="condicaoFinalizadora"
                              register={condicaoRegister}
                              isError={
                                finalizadoraInit &&
                                !!formStateCondicao.errors.condicaoFinalizadora
                              }
                              control={condicaoControl}
                              options={finalizadoras}
                              changeSelected={(selected) => {
                                setFinalizadoraInit(false);
                                setValueCondicao(
                                  'condicaoFinalizadora',
                                  selected,
                                );
                              }}
                            />
                          </Col>
                          <Col className="dias-container" sm={12} md={2}>
                            <InputNumberDias
                              label="Condição"
                              min={0}
                              value={numCondicao}
                              placeholder=""
                              isDisabled={false}
                              setInvalid={
                                !!formStateCondicao.errors.numCondicao
                              }
                              iniInicializado={
                                !!formStateCondicao.errors.numCondicao
                              }
                              onChange={(val: any) => {
                                clearErrorsCondicao('numCondicao');

                                if (Number(val) >= 0) {
                                  setValueCondicao('numCondicao', val);
                                  setNumCondicao(val);
                                }
                              }}
                            />
                          </Col>
                          <Col sm={12} md={4} className="condicao-container">
                            <InputSelect
                              label="Tipo da Condição"
                              name="condicao"
                              register={condicaoRegister}
                              isError={!!formStateCondicao.errors.condicao}
                              control={condicaoControl}
                              options={condicoesOptions}
                              changeSelected={(selected: any) => {
                                setValueCondicao('condicao', selected);
                              }}
                            />
                          </Col>
                          <Col
                            sm={12}
                            md={3}
                            className="button-container"
                            style={{
                              display: 'flex',
                              alignItems: 'flex-end',
                              paddingRight: '0px',
                              paddingLeft: '0px',
                            }}
                          >
                            <ConfirmButton
                              style={{ height: '42px' }}
                              onClick={() => {
                                setFinalizadoraInit(true);
                                handleCondicao();
                              }}
                            >
                              {condicaoAEditar ? 'Editar' : 'Confirmar'}
                            </ConfirmButton>
                            <CancelButton
                              style={{ height: '42px' }}
                              onClick={handleClearCondicao}
                            >
                              Cancelar
                            </CancelButton>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>

              {/* fim accordion */}
              <Row>
                <Col
                  sm={12}
                  className="dataTable"
                  style={{ maxHeight: 320, width: '100%' }}
                >
                  <Col
                    style={{
                      display: 'flex',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <DataGrid
                        className="myDataGrid"
                        rows={condicoes}
                        columns={colunasCondicao}
                        pageSize={4}
                        autoHeight
                        sortModel={[
                          {
                            field: 'num_condicao',
                            sort: 'asc',
                          },
                        ]}
                        columnBuffer={8}
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnMenu
                        disableSelectionOnClick
                        localeText={{
                          noRowsLabel: 'Nenhum registro encontrado',
                          columnMenuLabel: 'Menu',
                          columnMenuFilter: 'Filtrar',
                          columnMenuHideColumn: 'Esconder',
                          columnMenuUnsort: 'Não ordenar',
                          columnMenuSortAsc: 'Ordernar ASC',
                          columnMenuSortDesc: 'Ordernar DESC',
                          columnMenuShowColumns: 'Mostrar colunas',
                        }}
                      />
                    </div>
                  </Col>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </FormDefault>
      )}
    </Container>
  );
};

export default Loja;
