import styled, { keyframes } from 'styled-components';
import Button from 'react-bootstrap/Button';

const animationX = keyframes`
  0% {
    transform: translateX(100%);
    opacity:0;
    z-index: 0;
  }

  100% {

    transform: translateX(0%);
    opacity:1;
  }
`;

const animatioY = keyframes`
  0% {
    transform: translateY(100%);
    opacity:0;

  }

  100% {

    transform: translateY(0%);
    opacity:1;
  }
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const ContainerImage = styled.div`
  width: 70%;
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  background-color: ${({ theme: { colors } }) => colors.primary}!important;
  @media (max-width: 962px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  animation-name: ${animationX};
  animation-duration: 2s;
`;

export const ContainerForm = styled.div`
  background: #fff;
  display: flex;
  z-index: 100;
  justify-content: center;
  align-items: center;
  width: 30%;
  box-shadow: -3px 3px 5px 0px rgba(0, 0, 0, 0.16);
  height: 100vh;

  @media (max-width: 962px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  animation-name: ${animatioY};
  animation-duration: 1.5s;
  .logo {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonSubmit = styled(Button)`
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  background-color: ${({ theme: { colors } }) => colors.primary}!important;
  height: 40px;
  width: 100%;
  color: #fff !important;
  border-color: transparent !important;
  &:focus:not(.focus-visible) {
    box-shadow: none;
  }
  &:focus-visible {
    box-shadow: 0 0 0 3px ${({ theme: { colors } }) => colors.secondary} !important;
  }

  span {
    color: #fff !important;
    font-size: 16px !important;
    letter-spacing: 0.006rem;
    font-weight: 500 !important;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const LinkRecovery = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: ${({ theme: { colors } }) => colors.primary};
  margin-top: 10px;
  font-size: 0.9rem;

  &:hover {
    font-weight: 500;
  }
`;
