import { SelectProps } from '../protocols';

export const Tipolojas: SelectProps[] = [
  {
    value: 0,
    label: 'CD',
  },
  {
    value: 1,
    label: 'LOJA',
  },
  {
    value: 3,
    label: 'DEPÓSITO',
  },
  {
    value: 4,
    label: 'E-COMMERCE',
  },
  {
    value: 5,
    label: 'TRANSPORTADORA',
  },
];
