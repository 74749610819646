import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  position: relative;

  button:focus {
    outline: 0;
  }
  .dias-container input{
    height: 41px !important;
  }

  .btn-condicao-loja {
    float: right;
    padding: 8px 8px 8px 8px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    transition: 0.4s;
    color: #424242;
  }
  .btn-condicao-loja:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }

  .accordion-collapse {
    border-color: transparent;
    border: none !important;
  }

  // customização dataGrid

  .MuiDataGrid-columnHeader--moving {
    background: #8850bf !important;
  }

  .myDataGrid {
    min-height: 300px;
    max-height: 320px;
  }

  @media screen and (max-width: 991px) {
    .finalizadora-container {
      width: 40%;
      padding-left: 0px;
      padding-right: 0px;
    }

    .dias-container {
      width: 20%;
    }
    .condicao-container {
      width: 40%;
      padding-left: 0px;
      padding-right: 0px;
    }
    .button-container {
      width: 100% !important;
      margin-top: 15px;
      justify-content: flex-end;
    }
  }
  @media screen and (max-width: 768px) {
    .finalizadora-container {
      width: 75%;
      padding-left: 0px;
      padding-right: 0px;
    }

    .dias-container {
      width: 20%;
    }
    .condicao-container {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
    .button-container {
      width: 100% !important;
      margin-top: 15px;
      justify-content: flex-end;
    }
  }

  .input-container {
    position: relative;
    align-items: center;
    .min-button {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;
      margin-right: 1px;
      height: 40px;
      width: 40px;
      background: #8850bf;
      border-radius: 0px 4px 4px 0px;
      &:hover {
        background: #57069e;
      }
      &:focus {
        background: #57069e;
      }
    }
    input[type='number'] {
      padding-right: 25px !important;
    }
  }

  .css-1q8tsbm-Label {
    margin-top: 15px;
    color: #6b778c;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }

  .actButtonsCredito {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    @media screen and (min-width: 1000px) {
      button {
        width: 100%;
      }
    }

    @media screen and (max-width: 1000px) {
      .confirm {
        min-width: 130px !important;
        height: 42px;
      }
      .cancel {
        min-width: 130px !important;
        height: 42px;
      }
    }

    @media screen and (max-width: 991px) {
      margin-right: 0px !important .confirm {
        min-width: 100px !important;
      }
      .cancel {
        min-width: 150px !important;
      }
    }
 

  @media screen and (max-width: 991px) {
    .containerEmpresaGrupo {
      max-width: 77% !important;
    }
    .containerCalculoCondicao {
      min-width: 389px !important;
    }

    .containerButton {
      width: 100% !important;
      display: flex !important;
      justify-content: flex-end !important;
    }
  }

  @media screen and (min-width: 1500px) {
    .containerGrid {
      .MuiDataGrid-root .MuiDataGrid-columnHeader {
        min-width: 300px !important;
      }
      .MuiDataGrid-root .MuiDataGrid-cell--textLeft {
        min-width: 300px !important;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .containerEmpresaGrupo {
      max-width: 100% !important;
    }
    .containerFinalizadora {
      min-width: 100%;
    }

    .containerCalculoCondicao {
      min-width: 100% !important;
      .condicao {
        flex-direction: column;
        .numberDiv {
          min-width: 100%;
        }
        .selectDiv {
          min-width: 100%;
          margin-left: 0px !important;
        }
      }
    }
  }
`;
export const ContainerTab = styled.div`
  padding: 20px;
`;

export const InputLojas = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 41px;
  border: 2px solid #dfe1e6;
  border-radius: 3px;
  padding: 0px 5px 0px 1px;

  .ButtonLoja {
    background-color: #8850bf;
    margin: 4px 0px 10px 5px;
    color: #fff;
    border-radius: 5px;
    padding: 4px 25px 5px 30px;
    cursor: pointer;
    transition: 0.3s;
  }
  .form-check-input:focus {
    border-color: transparent !important;
    box-shadow: transparent !important;
  }
  .form-check-input:checked {
    background-color: #8850bf; //57069e
    border-color: transparent !important;
    transition: 0.3s;
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
  }
`;
